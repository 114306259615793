<template>
  <div class="container-fluid" style="margin-bottom:100px;">
    <div class="row">
    <div class="col col-sm-12  col-md-10 offset-md-1 ">
     
          <div class="row">
           
            <div class="col-8" style="margin-top:20px; margin-bottom:20px;">
              <h3 >Create Section</h3>
              </div>     
            
            <div class="col-4" style="margin-top:20px; margin-bottom:20px;" >
     
          <button type="button" @click="cancel()" class="btn btn-warning float-right">Cancel</button>


              <button style="margin-right:10px;" class="btn btn-danger float-right" @click="onSubmit">Save</button>
              

          </div>


        <div class="col-12 col-md-8">

                  <div>
                  <b>Title</b>
                  <input type="text" class="form-control" v-model="section.title" placeholder="give your content a title">
                  </div>
                      <!--  <div style="margin-top:20px; ">
                   <b>Description<em style="color:#999; font-size:13px;"> optional</em></b>
                     <b-form-textarea
                        v-model="section.description"
                        rows="1"
                        max-rows="2"
                      ></b-form-textarea>
                      </div> -->

                <div style="margin-top:20px; margin-bottom:20px;">
                  <b>Content Body</b>
                 <!-- <vue-editor v-model="section.content" /> -->
                   <vue-editor :editorOptions="editorSettings"  useCustomImageHandler @image-added="onfilepicked" v-model="section.content"></vue-editor>
              
                </div>

        </div> <!-- end right side-->

        <div class="col-12  col-md-4">
           <!-- <div style="margin-top:20px;">
              <b>Thumbnail:</b> <em>600px w x 400px h 72dpi</em>
                 <button @click="removebanner()" v-if="section.thumbnail"
                 class="btn btn-outline-danger float-right" 
                 style="position: relative; top:33px;">remove file</button>
       
              <fileupload @sendfile="recievefile" :target="'thumbnail'" ></fileupload>
              <img class="img-fluid" :src="section.thumbnail" v-bind:key="changenum">
            </div> -->

             <div style="margin-top:20px;">
                       <div style=" margin-bottom:30px;">
                <b-form-checkbox v-model="section.showonadmin" name="check-button" switch>
                 Show filter on admin filters
                </b-form-checkbox>
               </div>

              <b>Section Header Banner:</b> <br> <em style="font-size:12px;" >1920px-w - Accepts .mp4 video and .jpg image file</em>
              
                  <b-form-checkbox v-model="section.videosection" class=" m-2" name="check-button" switch>
                  Select if uplaoding a video.
                </b-form-checkbox>
                 <button @click="removesectionbanner()" v-if="section.sectionbanner"
                 class="btn btn-outline-danger float-right" 
                 style="position: relative; top:33px;">remove file</button>
       
                 <fileupload @sendfile="recievefile" :target="'sectionbanner'" ></fileupload>
                   <div class="col-12"  v-if="section.videosection" style="margin-bottom:30px;">
                    <video width="100%" height="auto" loop muted autoplay >
                      <source :src="section.sectionbanner" type="video/mp4" >
                      Your browser does not support the video tag.
                    </video>
                  </div>

                  <div class="col-12"   v-if="!(section.videosection)"  style="margin-bottom:30px;">
                    <img class="img-fluid" :src="section.sectionbanner" >
                </div>
            </div>
 
 
            <div style="margin-top:20px;">
              <b>Content Banner:</b><br> <em style="font-size:12px;" >2000px w x 300px h 72dpi</em> 
                 <button @click="removebanner()" 
                 class="btn btn-outline-danger float-right" 
                  v-if="section.banner"
                 style="position: relative; top:33px;">remove file</button>
       
              <fileupload @sendfile="recievefile" :target="'banner'" ></fileupload>
                <img class="img-fluid" :src="section.banner"  v-bind:key="changenum">
            </div>

        </div>

  </div>
  </div>
  </div>

  </div>
</template>
<script>

// import { VueEditor } from "vue2-editor";
import { VueEditor, Quill } from "vue2-editor";
import Fileupload from "../components/Fileupload";
import htmlEditButton from "quill-html-edit-button";
const ImageResize = require('quill-image-resize-vue').default
import * as fb from '../firebase';
Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/htmlEditButton', htmlEditButton)

export default {
components: {
    VueEditor,
  Fileupload
},

  data() {
    return {
      section: {
      title : "", 
      content : '',
      published: 1,
      sectionbanner: null
      },
      changenum: 1,
      editorSettings: {
          modules: {
          imageResize: {},
          htmlEditButton: {}
          }
        },
    };
  },


computed: {
    user() {
      return this.$store.getters["userProfile"];
    }
   
  },

  methods: {
      removebanner(){
      this.section.banner = ""
      },

      removethumbnail(){
      this.section.thumbnail = ""
      },
      
      removesectionbanner(){
      this.section.sectionbanner = ""
      },




         cancel() {
      this.$router.replace("/admin/Sections");
    },

 onfilepicked(file, Editor, cursorLocation, resetUploader){
      console.log('file',file)
      const files = file
      this.filename = files.name
       
       if (this.filename.lastIndexOf('.') <= 0) {
        return alert('Please select a valid file')
        }

        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
        })

        fileReader.readAsDataURL(files)
        this.image = files
        const filename = this.image.name
         return fb.storage.ref(filename).put(this.image)
         .then(fileData => {
           fileData.ref.getDownloadURL().then(function(url) {
           return url
            }).then(url => {
              // console.log(url);
              Editor.insertEmbed(cursorLocation, "image", url);
              resetUploader();
              // console.log('uploade file url', url);
              this.imageurl = url

              this.recievefile(url, this.target);
     })
    })
      .catch((error) => {
        console.log(error);
    })
    
  },
    recievefile(newfile, target){
      console.log('newfile',newfile);
      this.section[target] = newfile;
      this.changenum++ 

    },

    recievetext(val){
      console.log(val);
      this.section.content = val;
      console.log('this.section.content',this.section.content );
    },

    onSubmit() {
      console.log('submit', this.section);
      this.$store.dispatch("createsection", { vm: this, data: this.section, target: 'sections'});
      this.$router.replace("/admin");
    },


  },







};
</script>

<style>

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

</style>
