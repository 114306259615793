import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { auth } from '../firebase'
import Articles from '../views/Articles.vue'
import Createarticle from '../views/Createarticle.vue'
import Createnav from '../views/Createnav.vue'
import Navigation from '../views/Navigation.vue'
import Editarticle from '../views/Editarticle.vue'
import Editnav from '../views/Editnav.vue'
import Createsection from '../views/Createsection.vue'
import Editsection from '../views/Editsection.vue'
import Admin from '../views/Admin.vue'
import Content from '../views/Content.vue'
import Contact from '../views/Contact.vue'
import News from '../views/News.vue'
import Section from '../views/Section.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/home',
    name: 'Homepage',
    component: Home,
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/navigation',
    name: 'Navigation',
    component: Navigation,
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/content/:id/:category',
    name: 'Content',
    component: Content,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/section/:id',
    name: 'Section',
    component: Section,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/news',
    name: 'News',
    component: News,
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/admin/:section',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true
    }
  },


  {
    path: '/login',
    name: 'Login',
    component: () => import( /* webpackChunkName: "login" */ '../views/Login.vue')
  },

  {
    path: '/articles',
    name: 'Articles',
    component: Articles,
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/createarticle',
    name: 'Createarticle',
    component: Createarticle,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/editarticle/:id',
    name: 'Editarticle',
    component: Editarticle,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/createsection',
    name: 'Createsection',
    component: Createsection,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/editsection/:id',
    name: 'Editsection',
    component: Editsection,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/createnav',
    name: 'Createnav',
    component: Createnav,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/editnav/:id',
    name: 'Editnav',
    component: Editnav,
    meta: {
      requiresAuth: true
    }
  },

]

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0, behavior: 'smooth',};
  },
  routes
})

// // navigation guard to check for logged in users
router.beforeEach((to, _, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
