<template>
  <div class="row">
      <div class="col-12 d-none d-md-block">
        <div style="padding-left:15px; padding-right:15px;">
        <video width="100%" height="auto" loop  muted autoplay>
          <source  src="/video/steveintro.mp4" playsinline type="video/mp4" >
          Your browser does not support the video tag.
        </video>
        </div>
      </div>
      <div class="col-12 d-md-none d-sm-block ">
        <div style="padding-left:15px; padding-right:15px;">
        <video width="100%" height="auto" loop playsinline muted autoplay>
          <source  src="/video/steveintromobil.mp4" type="video/mp4" >
          Your browser does not support the video tag.
        </video>
        </div>
      </div>
  </div> 
</template>

<script>
export default {
  methods: {

  }
};
</script>

<style>
</style>