<template>
  <div class="container-fluid">
    <div class="row">
           <div class="col-12">
            <div style="padding:20px; background:#f5f5f5">
            <button class="btn btn-outline-danger" @click="switchview = 'Articles'" style="margin-right:10px;">Articles</button>
            <button class="btn btn-outline-danger" @click="switchview = 'Sections'" style="margin-right:10px;">Sections</button>
            <button class="btn btn-outline-danger" @click="switchview = 'Navigation'" style="margin-right:10px;">Navigation</button>
          <!--  <button class="btn btn-outline-danger" @click="switchview = 'Forms'" style="margin-right:10px;">Forms</button> 
            <button class="btn btn-outline-danger" @click="switchview = 'Settings'" style="margin-right:10px;">Settings</button> -->
            </div>
          </div>

           <div class="col-12">
           
             <articles v-if="switchview == 'Articles'" ></articles>
             <sections v-if="switchview == 'Sections'"></sections>
             <navigation v-if="switchview == 'Navigation'"></navigation>
             <forms v-if="switchview == 'Forms'"></forms>
             <settings v-if="switchview == 'Settings'"></settings>
          </div>
    </div>
  </div>
</template>

<script>
import Articles from "./Articles"
import Sections from "./Sections"
import Navigation from "./Navigation"
import Forms from "./Forms"
import Settings from "./Settings"

export default {
  data(){
    return {
      switchview: "Articles"
    }
  },

mounted() {
  this.checksection()
},

components: {
     Articles,
     Sections,
     Navigation,
     Forms,
     Settings
   },

  methods: {
    checksection: function(){
      if(this.$route.params.section == undefined){
          this.switchview = "Articles"
       //   console.log(this.$route.params.section)
         } else {
          this.switchview = this.$route.params.section;
       //  console.log(this.$route.params.section)
         }
      },


  }
};
</script>

<style>
.adminbutton {
  background:#f5f5f5; 
  padding:10px; 
  cursor:pointer;
  margin-top:1px;
}

.adminbutton:hover {
  background:#f1f1f1;

}
</style>