<template>
 <div class="container-fluid" style="margin-bottom:60px; padding-bottom:60px; background: #fff">
  <div class="row">
     
  </div> 

   
           
        </div>

</template>
<script>

export default {
  data() {
    return {
    };
  },


computed: {
     articles() {
      const articles = this.$store.getters["allarticles"];
      return articles.filter((o) => o.category =='news');
    }
  }

}
</script>

<style>
</style>
