<template>
  <div class="container-fluid">

      <div class="col-12" v-if="!(currentsection.sectionbanner)" style="height:160px; background-image: url('images/titlebanner.jpg');" >
        <h3 style="font-weight:900; text-align:center; color:#fff; padding-top:60px; color:#FFF;">
            {{currentsection.title}}
        </h3>
     </div> 

      <div class="row">
        <div class="col-12" v-if="currentsection.videosection" :key="key">
            <video width="100%" height="auto" loop playsinline muted autoplay >
              <source v-if="currentsection.sectionbanner" :src="currentsection.sectionbanner" type="video/mp4" >
              Your browser does not support the video tag.
            </video>
        </div>

        <div class="col-12"  v-if="!(currentsection.videosection)" >
          <img class="img-fluid" :src="currentsection.sectionbanner" >
        </div>  
      </div>


            <div class="row">

            <div class="col col-sm-12  col-md-10 offset-md-1 ">
            <div v-if="!(user.uid == null)" class="float-right">
            <router-link  title="edit" style="margin-top:14px; margin-right:20px;"
            :to="{ name: 'Editsection', params: {id : currentsection.id, category: currentsection.category}}">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#760000" class="bi bi-pencil-square" viewBox="0 0 16 16">
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
              </svg>
            </router-link> 
          </div>
     
          <div class="row" style="margin-top:30px;">

              <div class="col-lg-12">
               <img class="img-fluid" v-if="currentsection.banner" :src="currentsection.banner">
                <div class="contentviewer" v-html="currentsection.content"></div>
    
              <div class="row" v-if="articles.length > 4"> 
                <div class="col-12" style="background:#fff; height:44px; padding:10px;">
                  <span style="margin-right:10px;"><b>Sort: </b> | </span>
                  <span style="margin-right:10px; cursor:pointer;" 
                   @click="sortbyalpha('SORTBYALPHA')" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-sort-alpha-down" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M10.082 5.629 9.664 7H8.598l1.789-5.332h1.234L13.402 7h-1.12l-.419-1.371h-1.781zm1.57-.785L11 2.687h-.047l-.652 2.157h1.351z"/>
                  <path d="M12.96 14H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645V14zM4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293V2.5z"/>
                </svg>

                  </span>
                  
                  <span style=" margin-right:10px; cursor:pointer;"
                  @click="sortbyalpha('SORTBYALPHAREVERSE')" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-sort-alpha-up" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M10.082 5.629 9.664 7H8.598l1.789-5.332h1.234L13.402 7h-1.12l-.419-1.371h-1.781zm1.57-.785L11 2.687h-.047l-.652 2.157h1.351z"/>
                  <path d="M12.96 14H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645V14zm-8.46-.5a.5.5 0 0 1-1 0V3.707L2.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L4.5 3.707V13.5z"/>
                </svg></span>
                            
                 <span style="margin-right:10px;"><b>Sort by Date </b> | </span>
         
                <span style="margin-right:10px; cursor:pointer" 
                 @click="sortbyalpha('SORTBYDATE')" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-sort-down" viewBox="0 0 16 16">
                    <path d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                </svg></span>

                <span style="margin-right:10px; cursor:pointer" 
                  @click="sortbyalpha('SORTBYDATEREVERSE')" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-sort-up" viewBox="0 0 16 16">
                    <path d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                </svg></span>

                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-3" v-for="article in articles" :key="article.Id" style="margin-top:20px;">
                    
                    <div class="card" style="width: 100%;">
                     <div style="height:300px;  
                                background-repeat: no-repeat; 
                                background-size: cover; 
                                background-position: center;" :style="{ backgroundImage: `url(${article.thumbnail})` }">
                          
                                <div v-if="!(article.category == 'Scholarship Program')" style="font-size:11px; 
                                    padding:4px; 
                                    top:286px; 
                                    text-align:center; 
                                    color:#fff;
                                    width:100px; position:absolute;
                                    background-color:#af0000;">
                                    <em>{{moment(article.publisheddate).format('MM-DD-YYYY')}}</em>
                                </div>
                      </div>
                   
                    <div class="card-body" style="height:120px; background:#f5f5f5; overflow:hidden">
                      <b class="card-title" style="font-weight:bold">{{article.title}}</b>
                      <div v-if="(article.updated == true)||(article.updated == 'true')"
                      style="width:105px; position:absolute; margin-right:-20px; left:0px; bottom:20px; padding:8px; height:30px; background:#af0000">
                        <div style="font-size:11px; color:#fff;" ><b>STORY UPDATED</b></div>
                      </div>
                    </div>

                         <div class="cardlayer" @click="navigate(article)" >
                           <p style="font-size:11px; color:#f5f5f5" v-if="!(article.category == 'Scholarship Program')">
                           <em>{{moment(article.publisheddate).format('MM-DD-YYYY')}}</em></p>
                           <b style="font-weight:bold; font-size:16px; color:#fff">{{article.title}}</b><br>
                           <p class="card-text" style="color:#fff">{{article.description}}</p>
                           <button class="btn btn-outline-light">view</button>
                         </div>

                    </div>
                    </div>
                      
              </div>

              </div>   

          </div> 
       </div>  
    </div>
  </div>
</template>

<script>

export default {

 watch: {
        $route() {
            this.$nextTick(this.routeLoaded);
         }   
    },

mounted() {
       this.$router.onReady(() => this.routeLoaded());

    },

computed: {
     currentsection() {
      const sections = this.$store.getters["allsections"];
      return sections.filter((o) => o.title == this.$route.params.id)[0];
    },

    key(){
      return this.$route.path
    },

      allsections() {
      const sections = this.$store.getters["allsections"];
      return sections
    },

     articles() {
      const articles = this.$store.getters["allarticles"];
      return articles.filter((o) => o.category == this.$route.params.id);
    },

     user() {
      return this.$store.getters["userProfile"];
    },


    },

methods: {
  routeLoaded() {
           console.log('route loaded');
           if(this.$route.params.id == 'Scholarship Program'){
                this.sortbyalphainit()
           } else if (this.$route.params.id == 'News'){
            this.sortbydateinit() 
           }
        },


  sortbyalpha(sortmutation) {
    this.$store.commit(sortmutation)
  },

    sortbyalphainit() {
    console.log('SORTBYALPHA');
    this.$store.commit('SORTBYALPHA')
  },


    sortbydateinit() {
    console.log('SORTBYDATEREVERSE');
    this.$store.commit('SORTBYDATEREVERSE')
  },

  navigate(article){
       this.$router.push({ name: 'Content', params: {id : article.id, category : article.category}})
  }

}


}
</script>

<style  lang="css">
.sidebut {cursor:pointer; width:100%; border-bottom:1px solid #ccc;  background:#fff }
.sidebut :hover {
  background-color:#f9f9f9;
}

.card:hover {
  border:1px solid #af0000
}

.card:hover .cardlayer {
     display: block;

    -webkit-animation: fadeInFromNone 0.5s ease-out forwards;
    -moz-animation: fadeInFromNone 0.5s ease-out forwards;
    -o-animation: fadeInFromNone 0.5s ease-out forwards;
    animation: fadeInFromNone 0.5s ease-out forwards;
}
.ql-align-center{text-align: center; }

.cardlayer {
    opacity: 0;
    display: none;
    background-color: rgba(76, 0, 0, 0.8);
     z-index:1; 
     position:absolute; 
     padding:50px; 
     height:100%; 
     width:100%;
     cursor:pointer
}

@-webkit-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-moz-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-o-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@import "~vue2-editor/dist/vue2-editor.css";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
</style>

