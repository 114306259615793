<template>
  <div class="container-fluid">
   <div class="container-fluid" style="margin-bottom:100px;">
    <div class="row">
    <div class="col col-sm-12  col-md-10 offset-md-1 ">
     
          <div class="row">
            <div class="col-12" style="margin-top:20px; margin-left:-10px;margin-bottom:20px;">
              <h3 class="float-left">Edit Content</h3>

               

          <button type="button" @click="cancel()" class="btn btn-warning float-right">Cancel</button>
        

            <button  style="margin-right:10px; " class="btn btn-outline-danger float-right" @click="updateArticle">Save & Close</button>
               
            <button  style=" margin-right:10px; " class="btn btn-info float-right" @click="updateArticleandPreview">Save & Preview</button>
               
                <div>
 
  </div>
          </div>


        <div class="col-12 col-md-8">
           <div class="row" style="margin-bottom:30px;">
             <b-form-checkbox v-model="currentarticle.published" class="float-right m-2" name="check-button" switch>
              Published 
            </b-form-checkbox>

             <b-form-checkbox v-model="currentarticle.frontpage" class="float-right m-2" name="check-button" switch>
                Front Page
              </b-form-checkbox>

             <b-form-checkbox v-model="currentarticle.updated" class="float-right m-2" name="check-button" switch>
              Updated
              </b-form-checkbox>
          </div>
          
                  <div>
                  <b>Title</b>
                  <input type="text" class="form-control" v-model="currentarticle.title">
                  </div>
                    
                    <div style="margin-top:20px; ">
                   <b>Description</b>
                     <b-form-textarea
                        v-model="currentarticle.description"
                        rows="1"
                        max-rows="2"
                      ></b-form-textarea>
                      </div>



                <div style="margin-top:20px; margin-bottom:20px;">
                  <b>Content Body</b>
                  <vue-editor :editorOptions="editorSettings"  useCustomImageHandler @image-added="onfilepicked" v-model="currentarticle.content"></vue-editor>
                </div>

                



        </div> <!-- end right side-->

        <div class="col-12  col-md-4">


          <div class="row">
            <p style="margin-bottom:10px;"><b>Category:</b> <em>Select a category</em></p>
            <select class="custom-select" v-model="currentarticle.category" >
             <option v-for="section in allsections" v-bind:key="section.id" :value="section.title">{{section.title}}</option>
            </select>
      
          </div>

           <div class="row" style="margin-top:20px;">
                <label for="datepicker"><b>Published Date</b></label>
                <b-form-datepicker  
                 :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                id="datepicker" 
                v-model="currentarticle.publisheddate" class="mb-2"></b-form-datepicker>
              </div>
       

         <div class="row" style="margin-top:30px;">
            <div >
              <b>Thumbnail:</b> <em>600px w x 400px h 72dpi</em>
              <button @click="removethumbnail()" v-if="currentarticle.thumbnail"
              class="btn btn-outline-danger float-right" 
              style="position: relative; top:33px;">remove thumbnail
              </button>

              <fileupload @sendfile="recievefile" :target="'thumbnail'" ></fileupload>
                <img class="img-fluid" :src="currentarticle.thumbnail">
            </div>
        </div>

         <div class="row" style="margin-top:30px;">
            <div >
              <b>Banner:</b> <em>2000px w x 300px h 72dpi</em> 
              <button @click="removebanner()" v-if="currentarticle.banner"
              class="btn btn-outline-danger float-right" 
              style="position: relative;  top:33px;">remove banner</button>

              <fileupload @sendfile="recievefile" :target="'banner'" ></fileupload>
              <img class="img-fluid" :src="currentarticle.banner">
            </div>
        </div>


        </div>

  

  </div>

          <div style="margin-top:200px;">
          <button class="btn btn-danger" @click="deleteArticle(currentarticle)">Delete Article</button>
          </div>
  </div>
  </div>
  </div>


  </div>

   

</template>
<script>

import { VueEditor, Quill } from "vue2-editor";
import Fileupload from "../components/Fileupload";
import htmlEditButton from "quill-html-edit-button";
const ImageResize = require('quill-image-resize-vue').default
    import { mapGetters } from 'vuex';
import * as fb from '../firebase';
Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/htmlEditButton', htmlEditButton)

export default {
components: {
  VueEditor,
  Fileupload
},
  data() {
    return {
      editorSettings: {
          modules: {
          imageResize: {},
          htmlEditButton: {}
          }
        },
    };
  },

computed: {
     currentarticle() {
      const articles = this.$store.getters["allarticles"];
      return articles.filter((o) => o.id == this.$route.params.id)[0];
    },
    user() {
      return this.$store.getters["userProfile"];
    },
     ...mapGetters(['allsections']) ,
  },

  methods: {
removebanner(){
this.currentarticle.banner = ""
},

removethumbnail(){
this.currentarticle.thumbnail = ""
},

    cancel() {
      this.$router.replace("/admin/Articles");
    },

 onfilepicked(file, Editor, cursorLocation, resetUploader){
      console.log('file',file)
      const files = file
      this.filename = files.name
       
       if (this.filename.lastIndexOf('.') <= 0) {
        return alert('Please select a valid file')
        }

        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
        })

        fileReader.readAsDataURL(files)
        this.image = files
        const filename = this.image.name
         return fb.storage.ref(filename).put(this.image)
         .then(fileData => {
           fileData.ref.getDownloadURL().then(function(url) {
           return url
            }).then(url => {
              // console.log(url);
              Editor.insertEmbed(cursorLocation, "image", url);
              resetUploader();
              // console.log('uploade file url', url);
              this.imageurl = url

              this.recievefile(url, this.target);
     })
    })
      .catch((error) => {
        console.log(error);
    })
    
  },


    updateArticle() {
        this.$store.dispatch("updateArticle",  this.currentarticle);
        this.$router.replace("/articles");
    },

        updateArticleandPreview() {
        this.$store.dispatch("updateArticle",  this.currentarticle);
        this.$router.replace("/content/"+this.currentarticle.id+"/"+this.currentarticle.category);
    },

    recievefile(newfile, target){
      this.currentarticle[target] = newfile;
    },

     deleteArticle(article) {
      this.$store.dispatch("delete_article", article);
           this.$router.replace("/articles");
    },

    async getArticle() {
      try {
        await this.$store.getters["getArticle", this.$route.params.id];
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
  },
};
</script>

<style>

</style>
