<template>

    <div class="container-fluid">
      <div class="col-12">   
        <h5 class="float-left" style="margin-top:10px; font-weight:bold;">Sections</h5>  

   <router-link style="margin-top:10px;" tag="button" class="btn btn-danger float-right" :to="{ name: 'Createsection'}">Create Section</router-link>
      </div>
   <table class="table table-striped">
  <thead>
    <tr>
      <th scope="col" style="width:25%">Title</th>
      <th scope="col" style="width:25%">Url</th>
      <th scope="col"><span class="float-right">Edit</span></th> 
    </tr>
  </thead>
  <tbody>
    <tr  v-for="section in allsections" :key="section.id" >
      <td> 
         <router-link 
            tag="a"  style="font-weight:bold; color:#000"
            :to="{ name: 'Editsection', params: {id : section.id, category: section.category}}"
            >
            {{ section.title }}
            </router-link> 
       </td>
      <td> #/section/{{section.title}}</td>
 
   
      <td>   

      <router-link 
      class="float-right" style="cursor:pointer"
      :to="{ name: 'Editsection', params: {id : section.id}}"
      >
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#760000" class="bi bi-pencil-square" viewBox="0 0 16 16">
      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
      <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
    </svg>
      </router-link> 

    

        </td>

    </tr>
  </tbody>
</table>      
</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  computed : mapGetters(['allsections'])

}
</script>

<style>
</style>
