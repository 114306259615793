<template>

    <div class="container-fluid">
      <div class="col-12">   
        <h2 class="float-left" style="margin-top:10px; margin-left:-10px;">Settings</h2>  
  </div>    
</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  computed : mapGetters(['allarticles']),

}
</script>

<style>
</style>
