<template>
  <div class="container-fluid" style="background:#f5f5f5">
    <div class="row">

        <videobanner></videobanner>
      
        <div class="row" style="background:#f5f5f5; margin-top:-22px; padding-left:16px; padding-right:16px;">
          <div class="col-12">
                  <img src="images/mission.jpg" class="card-img-top" alt="">
           </div>
  
 <div class="col-12" v-if="articles.length > 0">
     <div class="row">
  <div class="col col-sm-12 " style="margin-top:20px; margin-bottom:20px; background: linear-gradient(45deg, #f5f5f5, #f1f1f1;">

              <div class="row"> 
              <div class="col-sm-12 col-md-6 col-lg-4" v-for="article in articles" :key="article.Id">
                    <div class="card" style="width: 100%;" >
                            <div style="padding:10px;  background: linear-gradient(45deg, #760000, #ff0000; color:#f5f5f5; font-weight:bold">
                              <div class="float-left" style="text-transform:uppercase; margin-top:5px;">{{article.category}}</div>
                              
                                         <div  class="float-right" v-if="(article.updated == true)||(article.updated == 'true')"
                      style="width:125px; position:absolute; right:0px; bottom:20px; padding:8px; height:30px; background:#af0000">
                        <div style="font-size:11px; color:#fff;" ><b>STORY UPDATED</b></div>
                      </div> 
                              </div> 
                    <div style="height:300px; overflow:hidden"> <img :src="article.thumbnail" class="card-img-top" alt=""></div>
                    <div class="card-body">
                      <h5 class="card-title" style="font-weight:bold; font-size:30px; color:#555; ">{{article.title}}</h5>
                        <!--<p style="font-size:13px; color:#fff"><em>Published:  {{ moment(article.created).calendar()}}</em></p>-->
                      
                      <p class="card-text" style="height:50px;">
                        {{article.description}}</p>

                 
                        <router-link 
                        tag="button"  
                        class="btn btn-danger btn-sm" 
                          :to="{ name: 'Content', params: {id : article.id, category : article.category}}"
                        >
                        View
                        </router-link>
                    </div>
                    </div>
                  </div>
              </div>
        </div>
  </div>  
    </div>  


  </div>
</div>
</div>

</template>

<script>

import Videobanner from "./Videobanner";

export default {

  components: {
    Videobanner
  },

  data() {
    return {
    };
  },

computed: {
     articles() {
      const articles = this.$store.getters["allarticles"];
      console.log('article',articles);
      return articles.filter((o) => o.frontpage == true);
    }
  }

};
</script>
