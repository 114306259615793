import moment from 'moment'
import Vue from 'vue'
import Vuex from 'vuex'
import * as fb from '../firebase'
import router from '../router/index'

Vue.use(Vuex)
// get all articles
fb.articlesCollection.orderByChild('publisheddate').on('value', (snapshot) => {
  let articlesArray = []
  snapshot.forEach(doc => {
    let article = doc.val()
    article.id = doc.key
    articlesArray.push(article)
  })
  
  store.commit('SET_ARTICLES', articlesArray)
})

// Get all Sections
fb.sectionsCollection.on('value', (snapshot) => {
  let sectionsArray = []

  snapshot.forEach(doc => {
    let section = doc.val()
  //  console.log('doc.key', doc.key);
    section.id = doc.key
    sectionsArray.push(section)
  })

 store.commit('SET_SECTIONS', sectionsArray)
})

fb.navigationCollection.on('value', (snapshot) => {
  let navigationArray = []

  snapshot.forEach(doc => {
    let nav = doc.val()
   // console.log('doc.key', doc.key);
    nav.id = doc.key
    navigationArray.push(nav)
  })

 store.commit('SET_NAVIGATION', navigationArray)
})



// Get user state
fb.auth.onAuthStateChanged((user) => {
  if (user) {
    store.commit('setUserProfile', user)
  } else {
    let tempuser = {uid : null}
    store.commit('setUserProfile', tempuser)
  }
  

});


const store = new Vuex.Store({

  state() {
    return{
    userProfile: {},
    articles: [],
    sections: [],
    navigation: [],
    currentarticle: {},
    currentsection: {}
    }
  },


  mutations: { //takes an object that you can define methods to change state - automatically gets current state as first argument
    
      setUserProfile(state, val) {
        state.userProfile = val
      },

      SET_ARTICLES(state, payload) {
        state.articles = payload;
      },

      SET_SECTIONS(state, payload) {
        state.sections = payload;
      },

      SET_NAVIGATION(state, payload) {
        state.navigation = payload;
      },

      ADD_ARTICLE(state, payload) {
        state.articles.push(payload);
      },

      ADD_NAVIGATION(state, payload) {
        state.navigation.push(payload);
      },

      ADD_SECTION(state, payload) {
        state.sections.push(payload);
      },

      UPDATE_ARTICLE(state, article) {
      console.log('UPDATE ARTICLE', state,article)
      },

      UPDATE_SECTION(state, section) {
        console.log('UPDATE SECTION', state,section)
        },

        UPDATE_NAVIGATION(state, nav) {
          console.log('UPDATE NAVIGATION', state, nav)
          },
    
  
          SORTBYALPHA(state){
            console.log('sortbyalpha');
            state.articles.sort(function(a, b) {
              var nameA = a.title.toUpperCase(); // ignore upper and lowercase
              var nameB = b.title.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1; //nameA comes first
              }
              if (nameA > nameB) {
                return 1; // nameB comes first
              }
              return 0;  // names must be equal
            }).reverse();
            },

            SORTBYALPHAREVERSE(state){
              state.articles.sort(function(a, b) {
                var nameA = a.title.toUpperCase(); // ignore upper and lowercase
                var nameB = b.title.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1; //nameA comes first
                }
                if (nameA > nameB) {
                  return 1; // nameB comes first
                }
                return 0;  // names must be equal
              })
              },

              SORTBYDATE(state){
                console.log('sortbydate');
                state.articles.sort(function(a, b) {
                  var nameA = moment(a.publisheddate) // ignore upper and lowercase
                  var nameB = moment(b.publisheddate)// ignore upper and lowercase
                 // console.log('nameA',nameA,'nameb',nameB );
                  if (nameA.isBefore(nameB)) {
                    return -1; //nameA comes first
                  }
                  if (nameB.isBefore(nameA)) {
                    return 1; // nameB comes first
                  }
                  return 0;  // names must be equal
                }).reverse();
                },

                SORTBYDATEREVERSE(state){
                  console.log('sortbydatereverse');
                  state.articles.sort(function(a, b) {
                    var nameA = moment(a.publisheddate)  // ignore upper and lowercase
                    var nameB = moment(b.publisheddate)// ignore upper and lowercase
                   // console.log('nameA',nameA,'nameb',nameB );
                    if (nameA < nameB) {
                      return -1; //nameA comes first
                    }
                    if (nameA > nameB) {
                      return 1; // nameB comes first
                    }
                    return 0;  // names must be equal
                  })
                  },


      DELETE_ARTICLE(state, article) {
          const index = state.articles.indexOf(article);
          if (index > -1) {
          state.articles.splice(index, 1);
          }
        },

        DELETE_NAVIGATION(state, nav) {
          const index = state.navigation.indexOf(nav);
          if (index > -1) {
          state.navigation.splice(index, 1);
          }
        },
         
      DELETE_SECTION(state, section) {
          const index = state.sections.indexOf(section);
          if (index > -1) {
          state.sections.splice(index, 1);
          }     
  }
 },
  actions: {
  //// CRUD /////////////////////////////////////////////////////////////////////// 
  // create
  async create(_, { vm, data, target }) {
      data.created = moment().toISOString(),
       fb.db.ref(target).push(data)
      .catch((error) => {
      console.log(error);
   })
    const payload = {data};      
    store.dispatch("ADD_ARTICLE", { vm: vm, payload: payload });
    },

    filterarticles(state, category){
      console.log(state.articles.filter(article => article.category == category));
    },

    async createsection(_, { vm, data, target }) {
      data.created = moment().toISOString(),
       fb.db.ref(target).push(data)
      .catch((error) => {
      console.log(error);
   })
    const payload = {data};      
    store.dispatch("ADD_SECTION", { vm: vm, payload: payload });
    },

    async createnav(_, { vm, data, target }) {
      data.created = moment().toISOString(),
       fb.db.ref(target).push(data)
      .catch((error) => {
      console.log(error);
   })
    const payload = {data};      
    store.dispatch("ADD_NAVIGATION", { vm: vm, payload: payload });
    },


    async sendmail(vm, to, message){
      console.log('to', to);
      console.log('vm', vm);
      console.log('message', message);
      fb.fs.collection('mail').add({
        to: to.to,
        message: to.message,
      })
    },

 // delete
    async delete_article(context, article) {
      await fb.db.ref('articles/' + article.id).remove()
      context.commit('DELETE_ARTICLE', article)
    },

    async delete_section(context, section) {
      await fb.db.ref('sections/' + section.id).remove()
      context.commit('DELETE_SECTION', section)
    },

    async delete_nav(context, nav) {
      await fb.db.ref('navigation/' + nav.id).remove()
      context.commit('DELETE_NAVIGATION', nav)
    },

  // update
  async updateArticle(context, article) {
    console.log('article', article.id, article);
    await fb.articlesCollection.child(`${article.id}`).update(article)
   // context.commit('UPDATE_ARTICLE', article)

  },

  async updateSection(context, section) {
    console.log('section', section.id, section);
    await fb.sectionsCollection.child(`${section.id}`).update(section)
   // context.commit('UPDATE_ARTICLE', article)

  },

  async updateNav(context, nav) {
    console.log('navigation', nav.id, nav);
    await fb.navigationCollection.child(`${nav.id}`).update(nav)
   // context.commit('UPDATE_ARTICLE', article)

  },

//// AUTH ///////////////////////////////////////////////////////////////////////
    async login({ dispatch }, form) {
      // sign user in
      const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)
      // fetch user profile and set in state
      dispatch('fetchUserProfile', user)
    },

    async fetchUserProfile({ commit }, user) {
      // set user profile in state
      commit('setUserProfile', user)
      // change route to dashboard
      if (router.currentRoute.path === '/login') {
        router.push('/')
      }
    },

    async logout({ commit }) {
      // log user out
      await fb.auth.signOut()
      // clear user data from state
      commit('setUserProfile', {})
      // redirect to login view
      router.push('/login')
    },

  },

  getters: {
   allarticles (state){  
    return state.articles.reverse()
    },
    allsections (state){  
      return state.sections
    },
    allnavigation (state){  
      return state.navigation
    },
    userProfile (state){  
      return state.userProfile
    },

    getarticlesbycategory: (state) => (category) => {
      return state.articles.filter(article => article.category === category);
   }

}
})

export default store
