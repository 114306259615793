<template>
  <div class="container-fluid">
    
    <div class="row" style="padding-top:20px; margin-bottom:100px; padding-bottom:30px;" >

    <!--  <div class="col-sm-12 col-lg-3 footerlogo" style="margin-top:20px;">
          <a href="#/section/Scholarship Program">
            <img class="mx-auto d-block img-fluid" style="margin-top:20px; max-width:240px; padding-bottom:20px"  src="images/brendawilson.png"> 
          </a>
      </div>

             
      <div class="col-sm-12 col-lg-3 footerlogo"  style="margin-top:20px;">
           <a href="#/section/Sunnyside%20Up">
            <img class="mx-auto d-block img-fluid" style="margin-top:20px; max-width:240px;  padding-bottom:20px" src="images/dcwarriorsbannersmall.png"> 
           </a>
      </div>

       
      <div class="col-sm-12 col-lg-3 footerlogo" style="margin-top:20px;" >
           <a href="#/section/Impact"> 
            <img class="mx-auto d-block img-fluid" style="margin-top:20px; max-width:200px;  padding-bottom:20px" src="images/communityimpact.jpg"> 
           </a>
      </div>

      <div class="col-sm-12 col-lg-3 footerlogo" style="margin-top:20px;">
         <a href="https://houstonpush.net/" target="_blank">
          <img class="mx-auto d-block img-fluid" style="margin-top:20px; max-width:240px;  padding-bottom:20px" src="images/houstonpushlogo.png"> 
         </a>
      </div> -->
    
   </div>

  </div>
</template>

<script>
export default {
};
</script>

<style>
.footerlogo img{cursor: pointer!important}
.footerlogo:hover{background:#f9f9f9}
</style>