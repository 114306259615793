<template>
  <div class="container-fluid">
     <div class="col-12" style=" margin-bottom:20px; height:160px;  background-image: url('images/titlebanner2.jpg');" >
          <h3 style="font-weight:900; text-align:center; color:#fff; padding-top:60px; color:#FFF;">
              {{currentarticle.title}}
              <p style="font-size:12px; margin-top:10px; font-weight:100;">  Published:     {{currentarticle.publisheddate}}</p>
          </h3>
     </div> 

    <div class="row">

      <div class="col col-sm-12  col-md-10 offset-md-1 ">

        <div class="row">
          

              <div class="col-lg-8">
               <img class="img-fluid" v-if="currentarticle.banner" :src="currentarticle.banner">
                <div class="contentviewer" v-html="currentarticle.content"></div>
               
               
                <div style="margin-top:100px;" v-if="!(user.uid == null)">
                  <router-link  style="margin-top:14px; margin-right:20px;"
              
                  :to="{ name: 'Editarticle', params: {id : currentarticle.id, category: currentarticle.category}}"
                  >
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#760000" class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                      <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                    </svg>
                  </router-link> 
                </div>
              </div>   


                <div class="col-lg-4">
  
                <div  v-for="article in allarticles" :key="article.Id">
                  <div class="sidebut" style="margin-bottom:3px;"> 
                  
                                        
              <router-link :to="{ name: 'Content', params: {id : article.id, category : article.category}}">
                <table>
                  <tr>
                    <td>   <img :src="article.thumbnail" style="width:60px; padding:5px;" ></td>
                    <td style="padding:10px;"><span style="color:#999; font-size:13px;">{{article.title}}</span></td>
                  </tr>
                </table>
              </router-link> 

                     
                     </div>
                </div>
              </div>  


          </div> 
       </div>  
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
    };
  },

computed: {
     currentarticle() {
      const articles = this.$store.getters["allarticles"];
      return articles.filter((o) => o.id == this.$route.params.id)[0];
    },

      allarticles() {
      const articles = this.$store.getters["allarticles"];
      return articles.filter((o) => o.category == this.$route.params.category);
    },

     user() {
      return this.$store.getters["userProfile"];
    }

  },


}

</script>

<style>
.sidebut {cursor:pointer; width:100%; border-bottom:1px solid #ccc;  background:#fff }
.sidebut :hover {
  background-color:#f9f9f9;
}

.contentviewer img {padding:10px;
  max-width: 100%; 
  height: auto;}
</style>

