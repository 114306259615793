<template>
  <div class="container-fluid">
   <div class="container-fluid" style="margin-bottom:100px;">
    <div class="row">
    <div class="col col-sm-12  col-md-10 offset-md-1 ">
     
          <div class="row">
            <div class="col-12" style="margin-top:20px; margin-bottom:20px;">
              <h4 class="float-left"><b>Edit Section:</b> {{currentsection.title}}</h4>
      
          <button type="button" @click="cancel()" class="btn btn-sm btn-warning float-right">Cancel</button>


            <button  style="margin-right:10px; " class="btn btn-sm  btn-danger float-right" @click="updatesection">Save & Close</button>
               
                        

                
                <div>
   
  </div>
   
 
          </div>

   
        <div class="col-12 col-md-8">
          
                  <div>
                  <b>Title</b>
                  <input type="text" class="form-control" v-model="currentsection.title">
                  </div>
                      <!--  <div style="margin-top:20px; ">
                   <b>Description</b>
                     <b-form-textarea
                        v-model="currentsection.description"
                        rows="1"
                        max-rows="2"
                      ></b-form-textarea>
                      </div> --> 


                <div style="margin-top:20px; margin-bottom:20px;">
                  <b>Content Body</b>
                  <vue-editor style="background:#fff" :editorOptions="editorSettings"  useCustomImageHandler @image-added="onfilepicked" v-model="currentsection.content"></vue-editor>
                </div>

                



        </div> <!-- end right side-->

        <div class="col-12  col-md-4">

         <div class="row" style="margin-top:30px;">
           <div>
            <div style="margin-left:18px; margin-bottom:30px;">
                <b-form-checkbox v-model="currentsection.showonadmin" name="check-button" switch>
                  show on admin filters
                </b-form-checkbox>
               </div>

              <div style="margin-left:18px; "><b>Section Header Banner:</b> <br> <em>1920px w - you can upload a .jpg or .mp4 video file</em></div>
                  <b-form-checkbox v-model="currentsection.videosection" class="float-right m-2" name="check-button" switch>
                  Select for Video File
                </b-form-checkbox>
              <fileupload @sendfile="recievefile" :target="'sectionbanner'" ></fileupload>
            </div>

            <div class="col-12" v-if="currentsection.videosection" style="margin-bottom:30px;">
              <video width="100%" height="auto" loop muted autoplay >
                <source :src="currentsection.sectionbanner" type="video/mp4" >
                Your browser does not support the video tag.
              </video>
           </div>

          <div class="col-12" v-if="!(currentsection.videosection)" style="margin-bottom:30px;">
            <img class="img-fluid" :src="currentsection.sectionbanner" >
      </div>


       <button @click="removesectionbanner()" v-if="currentsection.sectionbanner"
                 class="btn btn-sm  btn-outline-danger float-right" 
                 style="margin-left:10px; ">remove section banner</button>
 
        </div>
      
       <div class="row">
         <div class="col-12" style="margin-top:50px;">
          
              <b>Content Banner:</b> <em>2000px w x 300px h 72dpi</em> 
         

              <fileupload @sendfile="recievefile" :target="'banner'" ></fileupload>
              <img class="img-fluid" :src="currentsection.banner">
                   <button @click="removebanner()" v-if="currentsection.banner"
              class="btn btn-sm  btn-outline-danger float-right" 
              style="position: relative;  top:33px;">remove banner</button>
            </div>
        </div>

        </div>


          <div style="margin-top:200px;">
          <button class="btn btn-sm  btn-danger" @click="deletesection(currentsection)">Delete section</button>
          </div>

  </div>
  </div>
  </div>
  </div>


  </div>

   

</template>
<script>

import { VueEditor, Quill } from "vue2-editor";
import Fileupload from "../components/Fileupload";
import htmlEditButton from "quill-html-edit-button";
const ImageResize = require('quill-image-resize-vue').default
import * as fb from '../firebase';
Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/htmlEditButton', htmlEditButton)

export default {
components: {
  VueEditor,
  Fileupload
},
  data() {
    return {
      editorSettings: {
          modules: {
          imageResize: {},
          htmlEditButton: {}
          }
        },
    };
  },

computed: {
     currentsection() {
      const sections = this.$store.getters["allsections"];
      return sections.filter((o) => o.id == this.$route.params.id)[0];
    },
    user() {
      return this.$store.getters["userProfile"];
    },
    
  },

  methods: {
removebanner(){
this.currentsection.banner = ""
},

checkforvideo(){

},

removethumbnail(){
this.currentsection.thumbnail = ""
},

removesectionbanner(){
this.currentsection.sectionbanner = ""
},

     cancel() {
      this.$router.replace("/admin/Sections");
    },


 onfilepicked(file, Editor, cursorLocation, resetUploader){
      console.log('file',file)
      const files = file
      this.filename = files.name
       
       if (this.filename.lastIndexOf('.') <= 0) {
        return alert('Please select a valid file')
        }

        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
        })

        fileReader.readAsDataURL(files)
        this.image = files
        const filename = this.image.name
         return fb.storage.ref(filename).put(this.image)
         .then(fileData => {
           fileData.ref.getDownloadURL().then(function(url) {
           return url
            }).then(url => {
              // console.log(url);
              Editor.insertEmbed(cursorLocation, "image", url);
              resetUploader();
              // console.log('uploade file url', url);
              this.imageurl = url

              this.recievefile(url, this.target);
     })
    })
      .catch((error) => {
        console.log(error);
    })
    
  },


    updatesection() {
        this.$store.dispatch("updateSection",  this.currentsection);
              this.$router.replace("/admin/Sections");
    },

        updatesectionandPreview() {
        this.$store.dispatch("updatesection",  this.currentsection);
        this.$router.replace("/admin/Sections/"+this.currentsection.title);
    },

    recievefile(newfile, target){
      this.currentsection[target] = newfile;
    },

     deletesection(section) {
      this.$store.dispatch("delete_section", section);
            this.$router.replace("/admin/Sections");
    },

    async getsection() {
      try {
        await this.$store.getters["getsection", this.$route.params.id];
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
  },
};
</script>

<style>
@import "~vue2-editor/dist/vue2-editor.css";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
</style>
