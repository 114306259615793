<template>
<div class="container-fluid" style="margin-top:30px; margin-bottom:300px; min-height:800px; ">   
<div class="col col-12 offset-md-0 col-md-12 offset-lg-1 col-lg-10">

  <div class="row" >
      <div class="col-12" >
          <div class="float-left" style="margin-bottom:30px;"><h4>Edit Nav</h4> </div>

            
         <div class="float-right" style="margin-left:20px; margin-right:-10px;" >
          <button type="button" @click="cancel()" class="btn btn-warning">Cancel</button>
        </div>

                    <div class="float-right" style="margin-left:20px; margin-right:-10px;" >
          <button type="button" @click="updateNav()" class="btn btn-outline-danger">Save Nav Item</button>
  
        </div>


     </div>


<!-- Title -->
   
      <div class="col-sm-12 col-md-5" >

       <b>Title</b>
       <input type="text" 
              class="form-control" 
              v-model="navitem.title" 
              placeholder="Give your nav item a title ">
       </div>



      <div class="col-sm-12 col-md-5" v-if="navitem.dropdown == false">
       <b>Url</b>
       <input type="text" 
              class="form-control" 
              v-model="navitem.url" 
              placeholder="Type your url here. ">
       </div>



 <div class="col-sm-12 col-md-2" >
    <div class="custom-control custom-switch float-left" style="margin-left:10px; margin-top:30px;">
    <input type="checkbox" v-model="navitem.dropdown" class="custom-control-input" id="dropdown">
    <label class="custom-control-label" for="dropdown">Dropdown</label>
  </div>
   </div>
    
    
    <!-- Dropdown Creation Settings -->
    <div class="col-12" v-if="navitem.dropdown == true" style="margin-top:90px;">
   
          <div class="row" style="margin-bottom:30px;">
            <div class="col-sm-12" >
              <h5>Create Dropdown Links</h5> 
            </div>
          </div>
              
          <div class="row"  style="margin-bottom:20px;">

              <div class="col-sm-12 col-md-1" >
                <b>Sort</b>
              </div>

              <div class="col-sm-12 col-md-4" >
                  <b>Title</b>
                  <input type="text" class="form-control" v-model="dropitem.title" placeholder="Give your content a title ">
              </div>

              <div class="col-sm-12 col-md-5" >
                  <b>Url</b>
                  <input type="text" class="form-control" v-model="dropitem.url" placeholder="Add url here see - sections and article admins for internal urls">
              </div>


              
              <div class="col-sm-12 col-md-2" >
                <div style="margin-top:26px; cursor:pointer" class="float-right" @click="adddropdownitem()">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#760000" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
                </svg>
                </div>
              </div>


             </div>



              <div class="row" v-for="(drop, index) in navitem.dropdownarr" style="border-top:1px solid #ccc; padding-top:10px; padding-bottom:10px;" :key="drop.title"> 
               
        <div class="col-sm-12 col-md-1" >
                                <span style="cursor:pointer" @click="shiftarrayitem(index, 1, drop)"> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"/>
                    </svg>
                     </span>
                  <span style="cursor:pointer" @click="shiftarrayitem(index, -1, drop)"> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"/>
                  </svg>
                  </span>
         </div>
                             <div class="col-sm-12 col-md-4" >
                  <b>Title</b>: {{drop.title}}
              </div>

              <div class="col-sm-12 col-md-5" >
                  <b>Url</b>:   {{drop.url}}
              </div>


              <div class="col-sm-12 col-md-2" >
                 <div @click="deletedropdownitem(index)" style="cursor:pointer" class="float-right"> 
                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#760000" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                    </div>
              </div>


              </div>




      </div>
              <div style="margin-top:200px;">
          <button class="btn float-right btn-danger" @click="deletenav(navitem)">Delete Nav Item</button>
          </div>

</div>
</div><!-- End Main Row Container  -->   
</div><!-- Main Fluid Container --> 
</template>

<script>



export default {

  data() {
    return {
      dropitem : {}
    };
  },

computed: {
     navitem() {
      const navitem = this.$store.getters["allnavigation"];
      return navitem.filter((o) => o.id == this.$route.params.id)[0];
    },

    user() {
      return this.$store.getters["userProfile"];
    }
  },

methods : {
       shiftarrayitem: function(index, shiftnumber, element){
         console.log(index, shiftnumber, element);
         if ((index <= 0) && (shiftnumber == -1)){console.log('Nope')} else{
            let insertpoint = index+shiftnumber;
            this.navitem.dropdownarr.splice(index,1);
            this.navitem.dropdownarr.splice(insertpoint,0,element);
         }

     },

         cancel() {
      this.$router.replace("/admin/Navigation");
    },


     adddropdownitem: function(){
       if(!(this.navitem.dropdownarr)){
         this.navitem.dropdownarr = [];
         this.navitem.dropdownarr.push(this.dropitem);
       this.dropitem = {};
       } else {
       this.navitem.dropdownarr.push(this.dropitem);
       this.dropitem = {};}
     },

     deletedropdownitem: function(index){
       console.log(index);
       this.navitem.dropdownarr.splice(index,1);
     },


     deletenav(nav) {
      this.$store.dispatch("delete_nav", nav);
           this.$router.replace("/navigation");
    },

        updateNav() {
        this.$store.dispatch("updateNav",  this.navitem);
        this.$router.replace("/navigation");
    },


    
  }
};
</script>

<style>

</style>
