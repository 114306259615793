<template>

  <div class="row" style="margin-top:10px; margin-bottom:10px;"> 
  
    <div class="col-12" >
      <button type="button" @click="onpickfile()" style="margin-bottom:10px;" class="btn btn-danger">Select File</button>
      <input type="file" @change="onfilepicked($event)" style="display:none" ref="fileInput" accept="image/*,video/mp4,video/x-m4v,video/*">
    </div>

    <!-- <div class="col-12">
      <div style="padding:10px; border:1px solid #ccc;" >
        selected image
      <img :src="imageurl" class="img-fluid">
     </div>
    </div> -->

  </div>
  
</template>

<script>

import * as fb from '../firebase'
export default {

props: {
  target: String
},

  data() {
    return {
      image: null,
      filename: null,
      rawimgData: null,
      imageurl: ''

    };
  },


  methods: {
    onpickfile(){
      this.$refs.fileInput.click();
    },

    onfilepicked(event){
      const files = event.target.files
      this.filename = files[0].name
       
       if (this.filename.lastIndexOf('.') <= 0) {
        return alert('Please select a valid file')
        }

        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
        })

        fileReader.readAsDataURL(files[0])
        this.image = files[0]
        const filename = this.image.name
         return fb.storage.ref(filename).put(this.image)
         .then(fileData => {
           fileData.ref.getDownloadURL().then(function(url) {
           return url
           }).then(url => {
             console.log('uploade file url', url);
             this.imageurl = url
           this.$emit('sendfile', url, this.target);
     })
    })
      .catch((error) => {
        console.log(error);
      })


    }
  },
};
</script>

<style>
</style>