<template>

    <div class="container-fluid">
      <div class="col-12">   
        <h2 class="float-left" style="margin-top:10px; margin-left:-10px;">Forms</h2>  

   <router-link style="margin-top:10px;" tag="button" class="btn btn-danger float-right" :to="{ name: 'Createarticle'}">Create Article</router-link>

      </div>
   <table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">Title</th>
      <th scope="col">Published</th>
      <th scope="col">Url</th>
      <th scope="col"><span class="float-right">Edit</span></th> 
    </tr>
  </thead>
  <tbody>
    <tr  v-for="form in allforms" :key="form.id" >
      <td> 
         <router-link 
            tag="a"  style="font-weight:bold; color:#000"
            :to="{ name: 'Editarticle', params: {id : article.id, category: article.category}}"
            >
            {{ article.title }}
            </router-link> 
       </td>

      <td> {{article.published}}</td>
      <td> {{article.url}}</td>
      <td>   

      <router-link 
      tag="button"  
      style="width:100px"
      class="btn btn-danger btn-sm float-right" 
      :to="{ name: 'Editarticle', params: {id : article.id, category: article.category}}"
      >
      Edit
      </router-link> 

        </td>

    </tr>
  </tbody>
</table>      
</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  computed : mapGetters(['allarticles']),

}
</script>

<style>
</style>
