<template>

<div class="container-fluid">
      <div class="col-12">   
        <h2 class="float-left" style="margin-top:10px; margin-left:-10px;">Navigation</h2>  

   <router-link style="margin-top:10px;" tag="button" class="btn btn-danger float-right" :to="{ name: 'Createnav'}">Create Navigation</router-link>

      </div>
   <table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">Title</th>
      <th scope="col">Url</th>
      <th scope="col">Dropdown</th>
      <th scope="col"><span class="float-right">Edit</span></th> 
    </tr>
  </thead>
  <tbody>
    <tr  v-for="nav in allnavigation" :key="nav.id" >
      <td> 
         <router-link 
            tag="a"  style="font-weight:bold; color:#000"
            :to="{ name: 'Editnav', params: {id : nav.id}}"
            >
            {{nav.title }}
            </router-link> 
       </td>

      <td> 
        <span v-if="nav.url">{{nav.url}}</span>
        <span v-if="!(nav.url)"><b style="color:#af0000">edit to see other links</b></span>
      </td>
      <td>
            <span v-if="nav.dropdown">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
        </svg>  
        </span>

             <span v-if="!(nav.dropdown)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
        </span>
      </td>
      <td>   


      <router-link 
      class="float-right" style="cursor:pointer"
      :to="{ name: 'Editnav', params: {id : nav.id}}"
      >
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#760000" class="bi bi-pencil-square" viewBox="0 0 16 16">
      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
      <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
    </svg>
      </router-link> 
        </td>

    </tr>
  </tbody>
</table>      
</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  computed : mapGetters(['allnavigation']),

}
</script>

<style>
</style>
